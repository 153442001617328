import * as React from 'react';
import { graphql, Link } from 'gatsby';
import FreeLayout from '../layouts/free-layout/free-layout';

import * as styles from '../styles/pages/not-found.module.scss';

const NotFoundPage = ({ data }) => {
	return (
		<FreeLayout
			seo={{
				title: 'Stránka nenalezena',
				description: data?.api?.seo?.siteDescription,
				allowIndexing: false,
			}}
		>
			<section className={styles.notFoundSection}>
				<h1>Stránka nenalezena</h1>

				<p>Stránka, kterou se snažíte navštívit, neexistuje.</p>

				<Link to="/" className="btn-border-dark">
					Zpět na domovskou stránku
				</Link>
			</section>
		</FreeLayout>
	);
};

export const query = graphql`
	query {
		api {
			seo: seoSingleton {
				siteDescription
			}
		}
	}
`;

export default NotFoundPage;
